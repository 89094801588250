/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';
import { IADDCIRCLE } from '../../components/Modals/Circle/Circle';

// reference : https://zoie-rest.uk.r.appspot.com/swagger/index.html
export interface ICircle {
    'id': number,
    'circleName': string,
    'streamTokenId': string,
    'circleCode': string,
    'circleDescription': string,
    'circleLogo': string,
    'circleBanner': string,
    'addedBy': number,
    'createdAt': string,
    'addedByNavigation': null | any,
    'announcements': any[],
    'circleMembers': any[],
    'events': any[],
    'stageCircles': any[],
    'topicCircles': any[]
}

export interface TermsState {
  circles: ICircle[];
  circlesLoadingStatus: ILoadStatus;
  selectedCircleMembersCount : number;
  selectedCircleMembersCountLoadingStatus : ILoadStatus,
  selectedCircleIsMember :boolean;
  selectedCircleIsMemberLoadingStatus : ILoadStatus,
}

const initialState: TermsState = {
  circles: [],
  circlesLoadingStatus: Status.Idle,
  selectedCircleMembersCount: 0,
  selectedCircleMembersCountLoadingStatus: Status.Idle,
  selectedCircleIsMember: false,
  selectedCircleIsMemberLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchCircles = createAsyncThunk(
  'circles/fetchCircles',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/circle/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IFetchMemberCount {
  circleId : number
}
export const fetchMemberCount = createAsyncThunk(
  'circles/fetchMemberCount',
  async ({ circleId }: IFetchMemberCount) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:number = 0;
    await APP_URL.get(`/circle/getMemberCount/${circleId}`,
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IFetchIsMember {
  circleId : number
  userId: number
}
export const fetchIsMember = createAsyncThunk(
  'circles/fetchIsMember',
  async ({ circleId, userId }: IFetchIsMember) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:boolean = false;
    await APP_URL.get('/circle/isMember',
      {
        headers: { Authorization: `Basic ${base64String}` },
        params: {
          circleId,
          userId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addCircle = createAsyncThunk(
  'members/addCircle',
  async (body:IADDCIRCLE) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/circle', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated permissions');
        }
      }).catch(() => console.log('Unsuccessfully updated permissions'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditCircle{
  body:IADDCIRCLE;
  entityId : number;
}
export const editCircle = createAsyncThunk(
  'members/editCircle',
  async (payload:IEditCircle) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/circle/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated circle');
          // window.location.href = '/contentmanagement';
        }
      }).catch(() => console.log('Unsuccessfully updated circle'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const circlesSlice = createSlice({
  name: 'circles',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCircles.pending, (state) => {
        state.circlesLoadingStatus = Status.Loading;
      })
      .addCase(fetchCircles.fulfilled, (state, action) => {
        state.circlesLoadingStatus = Status.Idle;
        state.circles = action.payload;
      })
      .addCase(fetchMemberCount.pending, (state) => {
        state.selectedCircleMembersCountLoadingStatus = Status.Loading;
      })
      .addCase(fetchMemberCount.fulfilled, (state, action) => {
        state.selectedCircleMembersCountLoadingStatus = Status.Idle;
        state.selectedCircleMembersCount = action.payload;
      })
      .addCase(fetchIsMember.pending, (state) => {
        state.selectedCircleIsMemberLoadingStatus = Status.Loading;
      })
      .addCase(fetchIsMember.fulfilled, (state, action) => {
        state.selectedCircleIsMemberLoadingStatus = Status.Idle;
        state.selectedCircleIsMember = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = circlesSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectCircles = (state: RootState) => state.circles;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default circlesSlice.reducer;
