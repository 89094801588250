/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  Navigate,
} from 'react-router-dom';
import { RootState } from '../redux/store';

export interface ISettingsState {
  userDetails: any;
  signUpDetails: any;
  preSignUpAuthKey: string;
}

function getStorageValue(key :string, defaultValue:any) {
  // getting stored value
  const saved:any = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

const initialState: ISettingsState = {
  userDetails: getStorageValue('userDetails', {}),
  signUpDetails: {},
  preSignUpAuthKey: '',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logIn: (state, { payload }) => {
      localStorage.setItem('authKey', payload.authKey); // should be moved to auth Slice
      localStorage.setItem('userDetails', JSON.stringify(payload.user)); // should be moved to auth Slice
      // Remeber to store values to local storage to persit
      state.userDetails = { ...state.userDetails, ...payload.user };
    },
    setSignUpAuthKey: (state, { payload }) => {
      state.preSignUpAuthKey = payload.preSignUpAuthKey;
    },
    setSignupDetails: (state, { payload }) => {
      state.signUpDetails = { ...state.signUpDetails, ...payload.signUpDetails };
    },
    signOut: (state) => {
      // Remeber to store values to local storage to persit
      // remember to clear local storage and remove user details
      localStorage.removeItem('authKey');
      localStorage.removeItem('userDetails');
      state.userDetails = {};
      window.location.href = '/'; // hard reload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});
export const { logIn, setSignupDetails, signOut } = authSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectAuth = (state: RootState) => state.auth;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default authSlice.reducer;
