/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDPodcastTopic {
  podcastId: number;
  topicId: number;
}
export interface IAddMultiplePodcastTopic {
  'podcastTopics': IADDPodcastTopic[],
}

// reference : https://zoie-rest.uk.r.appspot.com/swagger/index.html
export interface IPodcastTopic {
   id?: number;
   createdAt?:string;
   podcastId: number;
   topicId: number;
}

export interface TermsState {
  podcastTopics: IPodcastTopic[];
  podcastTopicsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  podcastTopics: [],
  podcastTopicsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchPodcastTopics = createAsyncThunk(
  'podcastTopics/fetchPodcastTopics',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/podcastTopic/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addPodcastTopic = createAsyncThunk(
  'podcastTopics/addPodcastTopic',
  async (body:IADDPodcastTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/podcastTopic', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('res', res);
          response.push(res);
          console.log('successfully added Podcast Topics');
        }
      }).catch(() => console.log('Unsuccessfully added Podcast Topics'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addPodcastTopics = createAsyncThunk(
  'podcastTopics/addPodcastTopics',
  async (body:IAddMultiplePodcastTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/podcastTopic/multiple', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added PodcastTopic');
        }
      }).catch(() => console.log('Unsuccessfully added PodcastTopic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditPodcastTopic{
  body:IADDPodcastTopic;
  entityId : number;
}
export const editPodcastTopic = createAsyncThunk(
  'podcastTopics/editPodcastTopic',
  async (payload:IEditPodcastTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/podcastTopic/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Podcast Topic');
        }
      }).catch(() => console.log('Unsuccessfully updated Podcast Topic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeletePodcastTopic{
  entityId : number;
}
export const deletePodcastTopic = createAsyncThunk(
  'podcasts/deletePodcastTopic',
  async (payload:IDeletePodcastTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/podcastTopic/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Podcast Topic');
        }
      }).catch(() => console.log('Unsuccessfully deleted Podcast Topic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IDeleteMultiplePodcastTopic {
  'podcastId': number,
  'topicIds': number[],
}

export const deletePodcastTopics = createAsyncThunk(
  'podcasts/deletePodcastTopics',
  async (body:IDeleteMultiplePodcastTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post(`/podcastTopic/delete-multiple/${body.podcastId}`, body.topicIds,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted podcastTopics');
        }
      }).catch(() => console.log('Unsuccessfully deleted podcastTopics'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const podcastsTopicSlice = createSlice({
  name: 'podcasts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodcastTopics.pending, (state) => {
        state.podcastTopicsLoadingStatus = Status.Loading;
      })
      .addCase(fetchPodcastTopics.fulfilled, (state, action) => {
        state.podcastTopicsLoadingStatus = Status.Idle;
        state.podcastTopics = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = podcastsTopicSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectPodcastTopic = (state: RootState) => state.podcastsTopic;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default podcastsTopicSlice.reducer;
