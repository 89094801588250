// eslint-disable-next-line import/no-anonymous-default-export
import Logo from './SideBarLogo.svg';
import SearchIcon from './SearchIcon.svg';
import ValidNotificationIcon from './ValidNotificationIcon.svg';
import TopMenuIcon from './TopMenuIcon.svg';
import LandingBackground from './background.svg';
import LandingBackgroundMobile from './background-mobile.svg';
import LandingBackgroundHome from './onboarding-background.svg';
import LandingBackgroundLong from './backgroundLong.svg';
import SignupLandingBackground from './background2.svg';
import LandingLogo from './LandingLogo.svg';
import LandingLogo2 from './zoie-logo-2.png';
import Spinner from './Spinner.svg';
import GoogleLogo from './GoogleLogo.svg';
import Forgotpassword from './Right-arrow.svg';

import Onboarding1 from './Onboarding1.svg';
import Onboarding2 from './Onboarding2.svg';
import Onboarding3 from './Onboarding3.svg';
import Onboarding4 from './Onboarding4.svg';

import DrawerIcon from './drawer-icon.svg';
import HomeIcon from './Menubar/Home-icon.svg';
import HomeIconActive from './Menubar/Home-icon-active.svg';
import CirclesIcon from './Menubar/Circles-icon.svg';
import CirclesIconActive from './Menubar/Circles-icon-active.svg';
import ProfileIcon from './Menubar/Profile-icon.svg';
import ProfileIconActive from './Menubar/Profile-icon-active.svg';
import ServicesIcon from './Menubar/Services-icon.svg';
import ServicesIconActive from './Menubar/Services-icon-active.svg';
import ResourcesIcon from './Menubar/Resources-icon.svg';
import ResourcesIconActive from './Menubar/Resources-icon-active.svg';
import ContraceptiveIcon from './Contraceptives.svg';
import ConsultsIcon from './Consults.svg';
import GiveIcon from './Give.svg';
import PersonalisedPathwwaysIcon from './Personalised-pathways.svg';
import EventsIcon from './Events.svg';
import symbBlogtomTrackerIcon from './symBlogtom-Tracker.svg';
import CycleTrackerIcon from './Cycle-tracker.svg';
import ConvosIcon from './Convos.svg';
import FertilityIcon from './Fertility.svg';
import GroupSesssionIcon from './Group-sessions.svg';
import MessagesIcon from './Messages.svg';
import ShopIcon from './Shop.svg';
import HomeBanner from './HomeBanner.svg';
import ActivityFeedNotification from './Activity-feed-notification.svg';
import Heart from './Heart.svg';
import Share from './Share.svg';
import Chat from './Chat.svg';
import FlowerSpinner from './flowerSpinner.svg';
import SampleProfilePicture from './SampleProfilePicture.svg';
import JoinedTick from './joinedTick.svg';
import BannerBackground from './Banner-background.svg';
import BannerBackgroundSmall from './Banner-Background2.svg';
import ResourcesWhite from './Resources-white.svg';
import AnonymousIcon from './AnonIcon.svg';

// Company Icons
import McKinsley from './McKinsley.svg';
import Discovery from './Discovery.svg';
import Momentum from './momentum-car-insurance 1.svg';
// Modal Icons
import NewMember from './AddNewMember.svg';
import NewCompany from './AddNewCompany.svg';
import BulkIcon from './BulkIcon.svg';

import SampleProfileImage from './SampleProfileImage.svg';
import appGoalsImage from './appGoalsImage.svg';
import MotherImage from './motherImage.svg';
import SocialMediaImage from './socialMediaImage.svg';
import MyCard from './MyCard.svg';
import CalendarPlus from './consultations.svg';
import Events from './shape.svg';
import ShoppingCart from './ShoppingCart.svg';
import Gift from './Gift.svg';

import Pen from './pen.svg';
import Capsule from './capsule.svg';
import Motherhood from './Motherhood.svg';
import Pregnancy from './Pregnancy.svg';
import PodcastIcon from './Podcast-icon.svg';
import BlogsIcon from './Blogs-icon.svg';
import AnnouncementIcon from './Announcement-Icon.svg';

import PcosOrEndo from './Pcos_Endo.svg';
import MentalHealth from './mental_health.svg';
import Wellness from './wellness.svg';

import PMS from './pms.svg';
import Nursing from './nursing.svg';

import VideoTime from './videotime.svg';
import ReadingTime from './readingtime.svg';
import Audio from './audio.svg';
import Ebooks from './ebooks.svg';
import Sections from './sections.svg';
import Video from './video.svg';
import Activities from './activities.svg';
import Learners from './learners.svg';
import NewMothersWellness from './NewMothersWellness.svg';
import NursingBorn from './NursingBorn.svg';

import Baby from './baby.svg';
import BabyClothes from './babyClothes.svg';

import MenstualSeries from './menstrualSeries.svg';

import Mothers2Mothers from './mothers2mothers.svg';
import Wagrau from './wagrau.svg';

import Menstruation1 from './menstruation_1.svg';
import Menstruation2 from './menstruation_2.svg';
import Uterus from './uterus_1.svg';
import ContraceptivePills from './contraceptive-pills.svg';

import CareServices from './careServices.svg';
import CareServicesActive from './careServicesActive.svg';
import OurCommunityActive from './ourCommunityActive.svg';
import OurCommunity from './ourCommunity.svg';

import BadgeIcon from './badge-icon.svg';
import JoinZoieIcon from './join-zoie-icon.svg';
import JoinZoieIcon2 from './join-zoie-icon-2.svg';
import MedicalIcon from './medical-icon.svg';
import MessageIcon from './message-icon.svg';
import DetailsIcon from './my-details-icon.svg';
import RewardIcon from './reward-icon.svg';
import SettingIcon from './setting-icon.svg';
import WalletIcon from './wallet-icon.svg';
import HeartIcon from './heart-icon.svg';

import ZoieProfileImage from './ZoieProfileImage.svg';

const Companies = [McKinsley, Discovery, Momentum];
export const Images = {
  Logo,
  SearchIcon,
  ValidNotificationIcon,
  TopMenuIcon,
  LandingBackground,
  LandingBackgroundMobile,
  LandingBackgroundHome,
  SignupLandingBackground,
  LandingLogo,
  Spinner,
  GoogleLogo,
  Forgotpassword,
  LandingLogo2,
  Onboarding1,
  Onboarding2,
  Onboarding3,
  Onboarding4,
  LandingBackgroundLong,
  DrawerIcon,
  HomeIcon,
  AnonymousIcon,
  HomeIconActive,
  CirclesIcon,
  CirclesIconActive,
  ProfileIcon,
  ProfileIconActive,
  ResourcesIcon,
  ResourcesIconActive,
  ServicesIcon,
  ServicesIconActive,
  BannerBackground,
  BannerBackgroundSmall,
  CareServiceIcons: {
    ContraceptiveIcon,
    ConsultsIcon,
    GiveIcon,
    PersonalisedPathwwaysIcon,
    EventsIcon,
    symbBlogtomTrackerIcon,
    CycleTrackerIcon,
    ConvosIcon,
    FertilityIcon,
    GroupSesssionIcon,
    MessagesIcon,
    ShopIcon,
    ResourcesWhite,
  },
  GeneralIcons: {
    HomeBanner,
    ZoieBadges: {
      Menstruation1,
      Menstruation2,
      Uterus,
      ContraceptivePills,
    },
    ZoieProfileImage,
  },
  ActivityFeedNotification,
  Heart,
  Share,
  Chat,
  FlowerSpinner,
  SampleProfilePicture,
  Companies,
  NewMember,
  BulkIcon,
  NewCompany,
  SampleProfileImage,
  ProfileIcons: {
    appGoalsImage,
    MotherImage,
    SocialMediaImage,
    MyCard,
    CalendarPlus,
    Events,
    ShoppingCart,
    Gift,
  },
  CircleIcons: {
    Capsule,
    Motherhood,
    Pregnancy,
    FertilityIcon,
    PodcastIcon,
    BlogsIcon,
    AnnouncementIcon,

  },
  SubCircleIcons: {
    PcosOrEndo,
    MentalHealth,
    Wellness,
  },
  PersonalizedPathPathwaysIcons: {
    PMS,
    VideoTime,
    ReadingTime,
    Audio,
    Ebooks,
    Sections,
    Video,
    Activities,
    Learners,
    NewMothersWellness,
    NursingBorn,
  },
  BlogsIcons: {
    Nursing,
    Baby,
    BabyClothes,
  },
  PodCastsIcons: {
    MenstualSeries,
  },
  GiveIcons: {
    Mothers2Mothers,
    Wagrau,
  },
  ProfilePageIcons: {
    BadgeIcon,
    JoinZoieIcon,
    JoinZoieIcon2,
    MedicalIcon,
    RewardIcon,
    DetailsIcon,
    SettingIcon,
    WalletIcon,
    MessageIcon,
    HeartIcon,
  },
  HomeNavigationIcons: {
    CareServices,
    CareServicesActive,
    OurCommunity,
    OurCommunityActive,
  },
  Pen,
  JoinedTick,
};

export default Images;
