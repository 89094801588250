/* eslint-disable import/prefer-default-export */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { RootState } from '../../redux/store';

export interface IADDLikeReaction {
    userId: string,
    activityId: string,
}
export interface IAddLikeResponse {
    id: string,
    kind: string,
    createdAt: string,
    updatedAt: string,
    activityID: string,
    userID: string,
}
export interface TermsState {
    addLike: IAddLikeResponse [];
}
const initialState: TermsState = {
  addLike: [],
};

export const addLike = createAsyncThunk(
  'userAuth/addLike',
  async (body: IADDLikeReaction) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.post('/userAuth/addLike', body,
      {
        headers:
          { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const reactionSlice = createSlice({
  name: 'reaction',
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  initialState,
});
// eslint-disable-next-line no-empty-pattern
export const { } = reactionSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectReaction = (state: RootState) => state.reaction;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default reactionSlice.reducer;
