/* eslint-disable no-restricted-syntax */
// program to merge and remove duplicate value from an array

export const getUniqueAfterMerge = (arr1:any[], arr2:any[]) => {
  // merge two arrays
  const arr = arr1.concat(arr2);
  const uniqueArr = [];

  // loop through array
  for (const i of arr) {
    if (uniqueArr.indexOf(i) === -1) {
      uniqueArr.push(i);
    }
  }
  return uniqueArr;
};

export default getUniqueAfterMerge;
