/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

// reference : https://zoie-rest.uk.r.appspot.com/swagger/index.html

export interface TermsState {
    isFollower : boolean;
    isFollowerLoadingStatus : ILoadStatus,
    isUnFollower :boolean;
    isUnFollowerLoadingStatus : ILoadStatus,
  }

const initialState: TermsState = {
  isFollower: false,
  isFollowerLoadingStatus: Status.Idle,
  isUnFollower: false,
  isUnFollowerLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

interface IFetchFollowFeed {
    followerId : number
    followingId : number
  }
export const fetchFollowFeed = createAsyncThunk(
  'circleMember/fetchFollowFeed',
  async ({ followerId, followingId }: IFetchFollowFeed) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:boolean = false;
    await APP_URL.get('/circleMember/followFeed',
      {
        headers: { Authorization: `Basic ${base64String}` },
        params: {
          followingId,
          followerId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          response = true;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

  interface IFetchUnFollowFeed {
    followerId : number
    followingId : number
  }

export const fetchUnFollowFeed = createAsyncThunk(
  'circleMember/fetchUnFollowFeed ',
  async ({ followerId, followingId }: IFetchUnFollowFeed) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:boolean = false;
    await APP_URL.get('/circleMember/unfollowFeed',
      {
        headers: { Authorization: `Basic ${base64String}` },
        params: {
          followingId,
          followerId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          response = true;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const circleMemberSlice = createSlice({
  name: 'circleMember',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchFollowFeed.pending, (state) => {
        state.isFollowerLoadingStatus = Status.Loading;
      })
      .addCase(fetchFollowFeed.fulfilled, (state, action) => {
        state.isFollowerLoadingStatus = Status.Idle;
        state.isFollower = action.payload;
      })
      .addCase(fetchUnFollowFeed.pending, (state) => {
        state.isUnFollowerLoadingStatus = Status.Loading;
      })
      .addCase(fetchUnFollowFeed.fulfilled, (state, action) => {
        state.isUnFollowerLoadingStatus = Status.Idle;
        state.isUnFollower = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = circleMemberSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectGoals = (state: RootState) => state.goals;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default circleMemberSlice.reducer;
