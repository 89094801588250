/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDTopicCircle {
    topicId: number,
    topicName?: string,
    circleId: number,
    circle?: any,
    topic?: any,
}

// reference : https://zoie-rest.uk.r.appspot.com/swagger/index.html
export interface ITopicCircle {
   id: number;
   topicName: string;
   topicId:number;
   circleId:string;
   createdAt:string;
   circle:any,
   topic: any,
}

export interface TermsState {
  topicCircles: ITopicCircle[];
  topicCirclesLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  topicCircles: [],
  topicCirclesLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchTopicCircle = createAsyncThunk(
  'topicCircles/fetchTopicCircles',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/topicCircle/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addTopicCircle = createAsyncThunk(
  'topicCircle/addTopicCircle',
  async (body:IADDTopicCircle) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/topicCircle', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Topic Circle');
        }
      }).catch(() => console.log('Unsuccessfully updated Topic Circle'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditTopicCircle{
  body:IADDTopicCircle;
  entityId : number;
}
export const editTopicCircle = createAsyncThunk(
  'topicCircle/editTopicCircle',
  async (payload:IEditTopicCircle) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/topicCircle/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Topic Circle');
        }
      }).catch(() => console.log('Unsuccessfully updated Topic Circle'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteTopicCircle{
  entityId : number;
}
export const deleteTopicCircle = createAsyncThunk(
  'TopicCircle/deleteTopicCircle',
  async (payload:IDeleteTopicCircle) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/topicCircle/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Topic Circle');
        }
      }).catch(() => console.log('Unsuccessfully deleted Topic Circle'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const topicCircleSlice = createSlice({
  name: 'topicCircle',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopicCircle.pending, (state) => {
        state.topicCirclesLoadingStatus = Status.Loading;
      })
      .addCase(fetchTopicCircle.fulfilled, (state, action) => {
        state.topicCirclesLoadingStatus = Status.Idle;
        state.topicCircles = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = topicCircleSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectTopicCircle = (state: RootState) => state.topicCircle;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default topicCircleSlice.reducer;
