import axios from 'axios';

const BASE_TWILIO_URL = process.env.REACT_APP_TWILIO_BASEURL;
const BASE_API_URL = process.env.REACT_APP_ZOEI_BASEURL;
export const APP_URL = axios.create({
  baseURL: BASE_API_URL,
});
export const APP_LOCAL_URL = axios.create({
  baseURL: 'https://localhost:5001/api/',
});
export const TWILIO_URL = axios.create({
  baseURL: BASE_TWILIO_URL,
});
