/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';
import getUniqueAfterMerge from '../../constants/Arrays';

export interface IADDMEMBER {
  authSystemId: string,
  emailAddress: string,
  firstName: string,
  userName: string,
  lastName: string,
  phoneNumber: string,
  city: string,
  country: string,
  stripeId: string,
  password: string,
  confirmPassword: string,
  profilePic: string,
  getStreamId: string,
}
export interface IUPDATEPROFILE {
  userId: number,
  authSystemId: string,
  emailAddress: string,
  firstName: string,
  userName: string,
  lastName: string,
  phoneNumber: string,
  city: string,
  country: string,
  stripeId: string,
  password: string,
  profilePic: string,
  getStreamId: string,
}
export interface ISignUpAuth {
  body: IADDMEMBER,
  authKey: string,
}
export interface IMembers {
  id: number
  firstName: string
  lastName: string
  userName: string
  phoneNumber: string
  authSystemId: string
  emailAddress: string
  stripeId: string
  corpId: any
  permissionLevel: number
  createdAt: string
  password: string
  country: string
  city: string
  profilePic: string
  getStreamId: string
  stripeCustomerId: any
  subscriptionLevel: number
  auraSubscriptionId: any
  isAuraActive: boolean
  stripeSubscriptionId: any
  refferalCode: string
  bookingId: any
  corp: any
  announcements: any[]
  auraDetails: any[]
  banners: any[]
  blogs: any[]
  circleMembers: any[]
  circles: any[]
  events: any[]
  guides: any[]
  legalDocs: any[]
  notificationAddedbyNavigations: any[]
  notificationRecipients: any[]
  notificationSenders: any[]
  passwordResets: any[]
  podcasts: any[]
  pollInformations: any[]
  pollQuestionOptions: any[]
  pollResults: any[]
  userDevices: any[]
  userGoals: any[]
  userStages: any[]
  userTopics: any[]
  zoieConnectionInvitees: any[]
  zoieConnectionReceivers: any[]
  zoieReferralReferreds: any[]
  zoieReferralReferrers: any[]
}
export interface IADDMember {
  stageName:string;
  logoUrl: string,
  subHeader: any,
  stageDesc:string;
}
export interface IStoredEmail {
  email: any;
}
export interface IStoredPhoneNumber {
  phoneNumber: any;
}

export const fetchMembers = createAsyncThunk(
  'members/fetchMembers',
  async () => {
    const base64String = await process.env.REACT_APP_AUTHKEY_APP ?? '';
    let response:any[] = [];
    await APP_URL.get('/userauth/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
interface IAggregatedActivity{
  userId:number;
}

export const fetchAggregatedActivitiesByUser = createAsyncThunk(
  'members/fetchAggregatedActivitiesByUser',
  async ({ userId }:IAggregatedActivity) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get(`/userauth/getAggregatedActivities/${userId}`,
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IGetEnrichedActivity{
  userName:string;
  viewerHandle: string;
  start: number;
}

export const fetchEnrichedActivities = createAsyncThunk(
  'members/fetchEnrichedActivities',
  async ({ userName, viewerHandle, start }: IGetEnrichedActivity) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/userauth/getEnrichedActivities',
      {
        headers: { Authorization: `Basic ${base64String}` },
        params: {
          userName,
          viewerHandle,
          start,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addMember = createAsyncThunk(
  'members/addMember',
  async ({ body, authKey }:ISignUpAuth) => {
    let response:any[] = [];
    await APP_URL.post('/userauth', body,
      {
        headers: { Authorization: `Basic ${authKey}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export interface IUpdateProfile {
  body: IUPDATEPROFILE,
  userId: number,
}
export const updateMember = createAsyncThunk(
  'members/updateMember',
  async ({ body, userId }:IUpdateProfile) => {
    console.log('updateMember-body', body);
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/userauth/${userId}`, body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          // response.push(res);
          console.log('response: ', res);
        }
      }).catch(() => {
        // response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
interface IAddPostFromUser {
  image: string,
  text: string,
  posterId: number,
  postedToId: number,
  type: string,
  link: string,
  title: string,
  isAnon: boolean,
}
export const addPostFromUser = createAsyncThunk(
  'members/addPostFromUser',
  async (body:IAddPostFromUser) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/userauth/addPostFromUser', body,
      {
        headers: { Authorization: `Basic ${base64String}` },

      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
interface IAddLikeResponse {
  userId : string;
  activityId : string;
}
export const addLikeResponse = createAsyncThunk(
  'members/addLikeResponse',
  async (body:IAddLikeResponse) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/userauth/addLike', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
interface IAddCommentResponse {
  userId:string;
  activityId:string;
  receiverId:string;
  comment:string;
}
export const addCommentResponse = createAsyncThunk(
  'members/addCommentResponse',
  async (body:IAddCommentResponse) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/userAuth/addComment', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const usernameOrEmailExists = createAsyncThunk(
  'members/usernameOrEmailExists',
  async (body:IADDMEMBER) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/userauth/doesUserNameOrEmailExist', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditMember{
  body:IADDMember;
  entityId : number;
}
export const editMember = createAsyncThunk(
  'members/editMember',
  async (payload:IEditMember) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/userauth/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully edited Member');
        }
      }).catch(() => console.log('Unsuccessfully edited Member'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
// interface IUpdateProfile{
//   body:IUPDATEPROFILE;
//   userId:number;
// }
// export const updateUserProfile = createAsyncThunk(
//   'members/updateUserProfile',
//   async ({ body, userId }:IUpdateProfile) => {
//     const base64String = await localStorage.getItem('authKey') ?? '';
//     let response:any[] = [];
//     await APP_URL.put(`/userauth/update-profile/${userId}`, body,
//       {
//         headers:
//       { Authorization: `Basic ${base64String}` },
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           response = res.data;
//         }
//       }).catch(() => {});
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   },
// );
export const storedEmail = createAsyncThunk(
  'members/storedCourseId',
  (emailStored : any) => {
    let response:IStoredEmail[] = [];
    response = emailStored;
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const storedPhoneNumber = createAsyncThunk(
  'members/storedPhoneNumber',
  (phoneNumberStored : any) => {
    let response:IStoredPhoneNumber[] = [];
    response = phoneNumberStored;
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const updateMembersPermission = createAsyncThunk(
  'members/updateMembersPermission',
  async (body:any[]) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put('/userauth/admin-multiple', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated permissions');
        }
      }).catch(() => console.log('Unsuccessfully updated permissions'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IUserReaction{
    id : string
    kind : string
    createdAt : string
    updatedAt : string
    activityID : string
    userID : string
    user: any
    data: any
    targetFeeds: any[]
    parentID: string
    latestChildren: any
    childrenCounters: any
}
export interface IEnrichedActivity {
  id:string
  text: string
  title: string
  object: string
  actor: string
  time : string
  verb: string
  circle: string
  origin: string
  target: string
  image: string
  foreignId: string
  profilePic: string
  reactions : any
  username:string
  link: string
  isAdmin: string
  isAnon: string
  userReations: IUserReaction []
}

export interface TermsState {
  members: IMembers[];
  membersLoadingStatus: ILoadStatus;
  aggregatedActivities: any [];
  updateMember: any [];
  aggregatedActivitiesLoadingStatus: ILoadStatus;
  updateMemberLoadingStatus: ILoadStatus;
  enrichedActivities: IEnrichedActivity [];
  enrichedActivitiesLoadingStatus: ILoadStatus;
  allEnrichedActivitiesRequested : IEnrichedActivity [];
  emailValue: IStoredEmail[] ;
  phoneNumberValue: IStoredPhoneNumber[] ;
}

const initialState: TermsState = {
  members: [],
  membersLoadingStatus: Status.Idle,
  aggregatedActivities: [],
  updateMember: [],
  aggregatedActivitiesLoadingStatus: Status.Idle,
  updateMemberLoadingStatus: Status.Idle,
  enrichedActivities: [],
  allEnrichedActivitiesRequested: [],
  enrichedActivitiesLoadingStatus: Status.Idle,
  emailValue: [],
  phoneNumberValue: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAllEnrichedActivitiesRequested: (state) => {
      state.allEnrichedActivitiesRequested = [];
    },
    setAllEnrichedActivitiesRequested: (state, { payload }) => {
      console.log('payload.newAllEnrichedActivitiesRequested', payload.newAllEnrichedActivitiesRequested);
      state.allEnrichedActivitiesRequested = [...payload.newAllEnrichedActivitiesRequested];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.membersLoadingStatus = Status.Loading;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.membersLoadingStatus = Status.Idle;
        state.members = action.payload;
      })
      .addCase(updateMembersPermission.pending, (state) => {
        state.membersLoadingStatus = 'loading';
      })
      .addCase(updateMembersPermission.fulfilled, () => {

      })
      .addCase(fetchAggregatedActivitiesByUser.pending, (state) => {
        state.aggregatedActivitiesLoadingStatus = Status.Loading;
      })
      .addCase(fetchAggregatedActivitiesByUser.fulfilled, (state, action) => {
        state.aggregatedActivitiesLoadingStatus = Status.Idle;
        state.aggregatedActivities = action.payload;
      })
      .addCase(updateMember.pending, (state) => {
        state.updateMemberLoadingStatus = Status.Loading;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.updateMemberLoadingStatus = Status.Idle;
        state.updateMember = action.payload;
      })
      .addCase(fetchEnrichedActivities.pending, (state) => {
        state.enrichedActivitiesLoadingStatus = Status.Loading;
      })
      .addCase(storedEmail.fulfilled, (state, action) => {
        state.emailValue = action.payload;
      })
      .addCase(storedPhoneNumber.fulfilled, (state, action) => {
        state.phoneNumberValue = action.payload;
      })
      .addCase(fetchEnrichedActivities.fulfilled, (state, action) => {
        state.enrichedActivitiesLoadingStatus = Status.Idle;
        state.enrichedActivities = action.payload;
        state.allEnrichedActivitiesRequested = getUniqueAfterMerge(state.allEnrichedActivitiesRequested, action.payload);
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { resetAllEnrichedActivitiesRequested, setAllEnrichedActivitiesRequested } = membersSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectMembers = (state: RootState) => state.members;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default membersSlice.reducer;
