/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDBanner {
    id: number,
    mainHeader: string,
    subHeader: string,
    imageUrl: string,
    bannerUrl: string,
    bannerContent: string,
    addedBy: number,
}

// reference : https://zoie-rest.uk.r.appspot.com/swagger/index.html
export interface IBannner {
    id: number,
    mainHeader: string,
    subHeader: string,
    imageUrl: string,
    bannerUrl: string,
    bannerContent: string,
    addedBy: number,
}

export interface TermsState {
  banners: IBannner[];
  bannersLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  banners: [],
  bannersLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchBanners = createAsyncThunk(
  'banner/fetchBanner',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/banner/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addBanner = createAsyncThunk(
  'topicCircle/addBanner',
  async (body:IADDBanner) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/banner', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Banner');
        }
      }).catch(() => console.log('Unsuccessfully updated Banner'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditBanner{
  body:IADDBanner;
  entityId : number;
}
export const editBanner = createAsyncThunk(
  'banner/editBanner',
  async (payload:IEditBanner) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/banner/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Banner');
        }
      }).catch(() => console.log('Unsuccessfully updated Banner'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteBanner{
  entityId : number;
}
export const deleteBanner = createAsyncThunk(
  'TopicCircle/deleteBanner',
  async (payload:IDeleteBanner) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/banner/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Banner');
        }
      }).catch(() => console.log('Unsuccessfully deleted Banner'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const bannerslice = createSlice({
  name: 'banners',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.bannersLoadingStatus = Status.Loading;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.bannersLoadingStatus = Status.Idle;
        state.banners = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = bannerslice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectBanners = (state: RootState) => state.banners;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default bannerslice.reducer;
