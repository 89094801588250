import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import membersReducer from '../features/data/memberSlice';
import authReducer from '../features/authSlice';
import settingsReducer from '../features/settingsSlice';
import circleReducer from '../features/data/circlesSlice';
import circleMemberReducer from '../features/data/circleMemberSlice';
import stageCircleReducer from '../features/data/stageCircleSlice';
import topicCircleReducer from '../features/data/topicCircleSlice';
import topicsReducer from '../features/data/topicsSlice';
import stagesReducer from '../features/data/stagesSlice';
import goalsReducer from '../features/data/goalSlice';
import bannersReducer from '../features/data/bannerSlice';
import blogsReducer from '../features/data/blogsSlice';
import podcastsReducer from '../features/data/podcastsSlice';
import podcastsTopicReducer from '../features/data/podcastsTopicSlice';
import guidesReducer from '../features/data/guideSlice';
import reactionReducer from '../features/data/reactionSlice';
import audioSessionReducer from '../features/data/audioSessionSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    members: membersReducer,
    settings: settingsReducer,
    circles: circleReducer,
    stageCircle: stageCircleReducer,
    topicCircle: topicCircleReducer,
    stages: stagesReducer,
    topics: topicsReducer,
    goals: goalsReducer,
    banners: bannersReducer,
    blogs: blogsReducer,
    podcasts: podcastsReducer,
    guides: guidesReducer,
    circleMember: circleMemberReducer,
    podcastsTopic: podcastsTopicReducer,
    reaction: reactionReducer,
    audioSession: audioSessionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
