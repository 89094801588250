import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import { Buffer } from 'buffer';
import { store } from './redux/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/reset.css';
import PageLoader from './components/PageLoader/PageLoader';

window.Buffer = Buffer;

const tagManagerArgs = {
  gtmId: 'GTM-N7NPVCH',
};

TagManager.initialize(tagManagerArgs);

const App = React.lazy(() => import('./App'));
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <Suspense fallback={<PageLoader />}>
          <App />
        </Suspense>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
